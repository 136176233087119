import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, useLocalizeMessage, Pill, H6, Card } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
import { getCaregiverDisplayNameCount, getCaregiverStatus, CaregiverStatus } from '../../helpers/caregivers.helpers';
import { getParticipantNumber } from '../../helpers/participants.helpers';
import { useOrgContext } from '../../hooks/use_org_context';
import { CaregiverIcon } from '../svgIcons/caregiver.icon';
export const ParticipantCaregiversListItem = ({ caregiver, studyCode, caregiverAssignments, onView }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const handleClick = () => {
        onView(caregiver);
    };
    const detailItems = [
        {
            hidden: org?.c_no_pii,
            title: translate('Email'),
            description: _jsx("p", { className: 'nucleus-text-sm text-text-secondary', children: caregiver.c_email }),
        },
        {
            title: translate('Invite Code'),
            description: caregiver.c_public_user.c_access_code && (_jsxs("div", { children: [_jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('Study code'), ": ", _jsx("span", { children: studyCode })] }), _jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('Invite code'), ": ", _jsx("span", { children: caregiver.c_public_user.c_access_code })] })] })),
            ...(caregiver.c_public_user.c_access_code
                ? {
                    actionContent: _jsx(Button, { onClick: handleClick, label: translate('View'), variant: 'transparent' }),
                }
                : {}),
        },
    ];
    const displayName = translate('Caregiver {count}', {
        count: getCaregiverDisplayNameCount(caregiver.c_caregiver_display_name),
    });
    const status = getCaregiverStatus(caregiver, caregiverAssignments);
    return (_jsxs(Card.Content, { className: 'w-full flex flex-col gap-y-4 bg-neutral-50 p-4 border border-gray-200 rounded-lg shadow-sm sm:p-8', children: [_jsxs("div", { className: 'w-full flex flex-row justify-between', children: [_jsxs("div", { className: 'flex flex-row flex-none w-1/4 justify-start gap-x-2', children: [_jsx(CaregiverIcon, { className: 'text-text-secondary' }), _jsxs("div", { children: [_jsx("div", { className: 'flex flex-row gap-x-2', children: [CaregiverStatus.ENABLED, CaregiverStatus.PENDING].includes(status) ? (_jsx(H6, { children: displayName })) : (_jsxs(_Fragment, { children: [_jsx("h6", { className: 'nucleus-text-h6 text-text-secondary', children: displayName }), _jsx(Pill, { dataTestId: 'disabled-caregiver-pill', label: 'Disabled', backgroundColor: 'redPale' })] })) }), _jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('ID'), ": ", _jsx("span", { children: getParticipantNumber(caregiver.c_public_user) })] })] })] }), _jsx("div", { className: 'flex-none w-3/4 bg-white', children: _jsx(ListInformation, { dataTestId: 'participant-caregiver-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }), _jsxs("div", { className: 'w-full flex flex-row justify-end gap-x-2', children: [_jsx(Button, { disabled: (!!caregiver.c_public_user.c_access_code && status === CaregiverStatus.DISABLED) ||
                            org?.c_no_pii ||
                            !!caregiver.c_public_user.c_account, label: translate('Edit'), variant: 'secondary' }), caregiver.c_public_user.c_access_code && status === CaregiverStatus.ENABLED && (_jsx(Button, { label: translate('Disable'), variant: 'secondary' })), caregiver.c_public_user.c_access_code && status === CaregiverStatus.DISABLED && (_jsx(Button, { disabled: !caregiver.c_public_user.c_access_code, label: translate('Enable'), variant: 'secondary' })), (!caregiver.c_public_user.c_access_code || !caregiver.c_public_user.c_access_code) && (_jsx(Button, { label: translate('Invite'), variant: 'primary' }))] })] }));
};
