import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalization } from 'libs.localization.react';
import { InlineNotification, useLocalizeMessage } from 'libs.nucleus';
import { PageHeader } from 'libs.react';
import { SectionExport } from '../../components/section_export';
import { SectionImport } from '../../components/section_import';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLocaleAccess } from '../../hooks';
import { getExportTableStatus } from '../../utils';
import { toSentenceCase } from '../../utils/string_utils';
export const AssetsView = ({ studyId, assetId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const { studyLocalizationStatus, isStudyEnglishOnly } = useContext(TranslationWorkbenchContext);
    const assetType = queryParams.get('assetType');
    const asset = {
        type: assetType,
        id: assetId,
        version: '1',
    };
    const { localization } = useLocalization(asset);
    const { locales, resourcesAssets, studyAssets, notificationsAssets } = useContext(TranslationWorkbenchContext);
    const allAssets = { ...notificationsAssets, ...resourcesAssets, ...studyAssets };
    const twId = localization?.getId();
    const currentAsset = twId ? allAssets[twId] : undefined;
    const currentRole = currentAsset?.role;
    const { checkIsValidLocale } = useLocaleAccess(currentRole);
    const filteredLocales = locales.filter(checkIsValidLocale);
    const pageTitle = currentAsset?.displayName || toSentenceCase(assetType);
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        { name: pageTitle, path: `/studies/${studyId}/build/translations/${assetId}` },
    ], [assetId, assetType, pageTitle]);
    if (assetId === undefined || assetType === undefined) {
        return _jsx(InlineNotification, { title: t('Missing asset information') });
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: pageTitle, breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' }, status: getExportTableStatus(studyLocalizationStatus) }), _jsx("hr", {}), !isStudyEnglishOnly && (_jsx(SectionImport, { asset: asset, locales: filteredLocales, trackingContext: { view: 'study', studyId, asset: pageTitle } }))] })] }));
};
