import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useLocalizeMessage, ModalWindow, Spinner, } from 'libs.nucleus';
import { StepIndicator } from '../step_indicator';
const noop = () => { };
export const MultiStepsModalWindow = ({ showSteps = true, isLoading, onCancel = noop, onSubmit = noop, stepStates, steps, submitLabel, title, }) => {
    const translate = useLocalizeMessage();
    const lastStep = steps.length - 1;
    const [currentStep, setCurrentStep] = useState(0);
    const disabled = !stepStates[currentStep]?.isEnabled;
    const goBack = useCallback(() => setCurrentStep(Math.max(0, currentStep - 1)), [currentStep]);
    const goForward = useCallback(() => setCurrentStep(Math.min(currentStep + 1, lastStep)), [currentStep, lastStep]);
    const callOnSubmit = useCallback(() => onSubmit(), [onSubmit]);
    const backButton = { label: translate('Back'), onClick: goBack };
    const continueButton = {
        label: translate('Continue'),
        onClick: goForward,
        disabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const doneButton = {
        label: submitLabel ?? translate('Save'),
        onClick: callOnSubmit,
        disabled,
    };
    return (_jsxs(ModalWindow, { title: title, isOpen: true, closeWindow: onCancel, footerSecondaryActionButtons: currentStep === 0 ? [cancelButton] : [cancelButton, backButton], footerPrimaryActionButton: currentStep === lastStep ? doneButton : continueButton, footerCenterContent: showSteps && _jsx(StepIndicator, { totalSteps: steps.length, currentStep: currentStep + 1 }), width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), steps[currentStep]] }));
};
