export const DEFAULT_DEBOUNCE_TIME = 250;
export const DEFAULT_PAGE_SIZE = 10;
// This code safely retrieves environment variables CI_COMMIT_SHORT_SHA and BUILD_DATE.
// It's structured this way because 'process' and 'process.env' are not globally
// available in all environments, but the specific env variables are.
// The try-catch ensures it works even if accessing process.env throws an error.
// This approach eliminates the need for local .env file configuration.
let CI_COMMIT_SHORT_SHA;
let BUILD_DATE;
let CI_COMMIT_TAG;
try {
    CI_COMMIT_TAG = (process.env.CI_COMMIT_TAG ?? '').replace('studio/', '');
    CI_COMMIT_SHORT_SHA = process.env.CI_COMMIT_SHORT_SHA ?? 'local';
    BUILD_DATE = process.env.BUILD_DATE ?? 'current';
}
catch (e) {
    CI_COMMIT_TAG = '';
    CI_COMMIT_SHORT_SHA = 'local';
    BUILD_DATE = 'current';
}
export const buildInfo = {
    CI_COMMIT_SHORT_SHA,
    BUILD_DATE,
    CI_COMMIT_TAG,
};
