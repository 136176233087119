import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage, Text, Icon, Tooltip } from 'libs.nucleus';
import { AuthContext, useScopeValidator, AclScope } from 'libs.react';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants/developer_flags.constants';
import { StudyConfigurationContext } from '../../contexts/study_configuration/study_configuration.context';
import { timelineNameToHash } from '../../utils/study/study.utils';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderLinkContent = (title, translate, isSectionHeader = false) => {
    return (_jsxs("div", { className: 'flex items-center gap-2', children: [_jsx(Text, { size: 'sm', className: `nucleus-flex-1 nucleus-truncate ${isSectionHeader ? 'nucleus-font-medium' : ''}`, children: title }), _jsx(Tooltip, { as: 'div', placement: 'end', title: translate('Study is in read-only mode to support translations activities.'), children: _jsx(Icon, { name: 'lockSolid', color: 'yellowDark', size: 'sm' }) })] }));
};
export const useNavigation = () => {
    const { studyId } = useParams();
    const { entityId, isInternalUser } = useContext(AuthContext);
    const { currentStudy, currentTimelineId, isReadOnlyMode } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const canDeploy = useScopeValidator(AclScope.MOM_ENVIRONMENT_GET, {
        resource: `entity/${entityId}/study/${studyId}`,
    });
    const canManageStudies = useScopeValidator([AclScope.MOM_STUDY_CREATE]);
    const canManageSites = useScopeValidator([AclScope.MOM_SITE_CREATE]);
    const navItems = useMemo(() => {
        const timelines = currentStudy.getScheduleTimelines();
        const scheduleHash = timelineNameToHash(timelines[currentTimelineId]?.name ?? timelines.onboarding.name);
        const items = [
            {
                icon: 'navCube',
                links: [
                    {
                        title: !isReadOnlyMode ? translate('Schedule') : renderLinkContent(translate('Schedule'), translate),
                        path: `/studies/${studyId}/build/schedule${scheduleHash}`,
                        ignoreFragments: true,
                        hidden: !canManageStudies,
                    },
                    {
                        title: !isReadOnlyMode ? translate('Resources') : renderLinkContent(translate('Resources'), translate),
                        path: `/studies/${studyId}/build/resources`,
                        hidden: !canManageStudies,
                    },
                    {
                        title: translate('Translations'),
                        path: `/studies/${studyId}/build/translations`,
                        hidden: !canManageStudies,
                    },
                ],
                path: `/studies/${studyId}/build`,
                title: !isReadOnlyMode ? translate('Build') : renderLinkContent(translate('Build'), translate, true),
                hidden: !canManageStudies,
            },
            {
                title: translate('Versions'),
                path: `/studies/${studyId}/versions`,
                icon: 'rectangleStack',
                hidden: !canManageStudies,
            },
            {
                title: translate('Tests'),
                path: `/studies/${studyId}/tests`,
                icon: 'window',
                hidden: !canManageStudies,
            },
        ];
        if (canDeploy) {
            items.push({
                title: translate('Deploy'),
                path: `/studies/${studyId}/deploy`,
                icon: 'navDeployment',
                hidden: !canManageStudies,
            });
        }
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.siteManagement)) {
            items.push({
                title: translate('Sites'),
                path: `/studies/${studyId}/build/sites`,
                icon: 'navSites',
                hidden: !canManageSites,
            });
        }
        if (isInternalUser) {
            items[0].links.push({
                title: !isReadOnlyMode ? translate('Extensions') : renderLinkContent(translate('Extensions'), translate),
                path: `/studies/${studyId}/build/extensions`,
                pillProps: { label: translate('Internal'), className: isReadOnlyMode ? 'text-[0.5rem]' : '' },
                hidden: !canManageStudies,
            }, {
                title: !isReadOnlyMode
                    ? translate('Notifications')
                    : renderLinkContent(translate('Notifications'), translate),
                path: `/studies/${studyId}/build/notifications`,
                pillProps: { label: translate('Internal'), className: isReadOnlyMode ? 'text-[0.5rem]' : '' },
                hidden: !canManageStudies,
            }, {
                title: !isReadOnlyMode
                    ? translate('Study summary')
                    : renderLinkContent(translate('Study summary'), translate),
                path: `/studies/${studyId}/build/study-summary`,
                pillProps: { label: translate('Internal'), className: isReadOnlyMode ? 'text-[0.5rem]' : '' },
                hidden: !canManageStudies,
            });
        }
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.review)) {
            items[0].links.push({
                title: translate('Review'),
                path: `/studies/${studyId}/build/review`,
                hidden: !canManageStudies,
            });
        }
        if (isInternalUser) {
            items[2].links = [
                {
                    title: translate('Test plans'),
                    path: `/studies/${studyId}/test-plans`,
                    pillProps: { label: translate('Internal') },
                    hidden: !canManageStudies,
                },
            ];
        }
        return items;
    }, [flagManager, studyId, currentTimelineId, isReadOnlyMode]);
    return { navItems };
};
