import { getApiInstance } from '../../../helpers/api.helpers';
export const onStartVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    await getApiInstance(environment).post(`routes/visit/action`, {
        c_public_user: participantId,
        c_visit: visitId,
        action: 'start',
    });
};
export const onSkipVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    await getApiInstance(environment).post(`routes/visit/action`, {
        c_public_user: participantId,
        c_visit: visitId,
        action: 'skip',
        skippedReason: 'User did not show up',
    });
};
