import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalizeMessage, Table, ModalWindow } from 'libs.nucleus';
import { useSiteSelectorTableColumns } from './site_selector_table_columns.hook';
import { SiteContext } from '../../contexts/site';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const SiteSelector = ({ isModalOpen, setIsModalClosed }) => {
    const translate = useLocalizeMessage();
    const { sites, indexedSites, isLoading, site, setSelectedSite } = useContext(SiteContext);
    const [selectedSiteId, setSelectedSiteId] = useState(site?._id ?? null);
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const handleSiteSelection = async () => {
        if (selectedSiteId && indexedSites) {
            setSelectedSite(indexedSites[selectedSiteId]);
            queryClient.invalidateQueries({
                queryKey: ['participantsList', environment?.id, selectedSiteId],
            });
            navigate(`/site/study/${environment?.id}/participants`);
            setIsModalClosed();
        }
    };
    const { siteSelectorColumns } = useSiteSelectorTableColumns({ setSelectedSiteId, selectedSiteId });
    return (_jsx(ModalWindow, { title: translate('Select site'), isOpen: isModalOpen, closeWindow: () => { }, dataTestId: 'change-site-modal', footerSecondaryActionButtons: [
            {
                disabled: false,
                label: translate('Cancel'),
                content: undefined,
                onClick: setIsModalClosed,
            },
        ], footerPrimaryActionButton: {
            disabled: selectedSiteId === site?._id,
            label: translate('Continue'),
            content: undefined,
            onClick: handleSiteSelection,
        }, width: 'full', removeBodyPadding: true, children: _jsx("div", { className: 'p-6', children: _jsx(Table, { columns: siteSelectorColumns, data: sites || [], hasPagination: true, initialPageSize: 10, isLoading: isLoading, labelNoResults: translate('No sites'), paginationNavigationScreenReaderLabel: translate('Site table navigation'), useInternalSearch: true, getRowId: (row) => row._id, highlightedRowIds: selectedSiteId ? [selectedSiteId] : [] }) }) }));
};
