import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { getLocaleMetaInfo } from 'libs.i18n-core';
import { Locale, RadioGroup, RadioGroupOption, Select, useLocalizeMessage } from 'libs.nucleus';
var LOCALE_EXPORT;
(function (LOCALE_EXPORT) {
    LOCALE_EXPORT["BASE"] = "base";
    LOCALE_EXPORT["CUSTOM"] = "custom";
})(LOCALE_EXPORT || (LOCALE_EXPORT = {}));
export const SectionLocale = memo(({ onChange, locales, initialSelectedIds }) => {
    const t = useLocalizeMessage();
    const [localeExport, setLocaleExport] = useState(LOCALE_EXPORT.BASE);
    const initialLocale = locales[initialSelectedIds?.[0] || 0];
    const { language, region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: initialLocale });
    const initialLocaleSelection = {
        label: `${initialLocale} (${language}/${region})`,
        value: initialLocale,
    };
    const [selectedOption, setSelectedOption] = useState(initialLocaleSelection);
    const localeSelectionOptions = locales.map((locale) => {
        const { language, region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: locale });
        return {
            label: `${locale} (${language}/${region})`,
            value: locale,
        };
    });
    const handleSetLocaleExport = useCallback((selection) => {
        setLocaleExport(selection);
        setSelectedOption(initialLocaleSelection);
        onChange({
            selectedLocales: [Locale.en_US],
        });
    }, []);
    const handleLocaleSelection = useCallback((selection) => {
        setSelectedOption(selection);
        onChange({
            selectedLocales: localeExport === LOCALE_EXPORT.BASE ? [Locale.en_US] : [selection.value],
        });
    }, [localeExport]);
    return (_jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs(RadioGroup, { label: t('Locale selection'), value: localeExport, onChange: handleSetLocaleExport, children: [_jsx(RadioGroupOption, { option: {
                            value: LOCALE_EXPORT.BASE,
                            label: t('Only export base language'),
                        } }), _jsx(RadioGroupOption, { option: {
                            value: LOCALE_EXPORT.CUSTOM,
                            label: t('Select locale to export'),
                        } })] }), localeExport === LOCALE_EXPORT.CUSTOM && (_jsx("div", { className: 'flex flex-col gap-5', children: _jsx(Select, { label: t('Locales'), description: t('A single locale can be exported at a time'), multiple: false, value: selectedOption, onChange: handleLocaleSelection, options: localeSelectionOptions }) }))] }));
});
SectionLocale.displayName = 'SectionLocale';
