import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { JobTypes } from 'libs.jobs.common';
import { useCreateJob } from 'libs.jobs.react';
import { ModalWindow, useLocalizeMessage, Icon } from 'libs.nucleus';
import { AssetLocalePairSelection } from './asset_locale_pair_selection.component';
export const GenTranslationMultiSelectionModal = ({ hideLocales = [], assets, locales, onCancel = () => { }, onSubmit = () => { }, }) => {
    const t = useLocalizeMessage();
    const [assetLocalePair, setAssetLocalePair] = useState([]);
    const filteredLocales = locales.filter((locale) => !hideLocales.includes(locale));
    const createJob = useCreateJob();
    const onDoneClick = () => {
        if (assetLocalePair.length === 0) {
            return;
        }
        for (const { locale, asset_id } of assetLocalePair) {
            createJob(JobTypes.TRANSLATION__GENERATE_TRANSLATION, { locale, asset_id });
        }
        onSubmit();
    };
    const doneButton = {
        label: t('Translate'),
        onClick: onDoneClick,
        disabled: assetLocalePair.length === 0,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onCancel,
    };
    return (_jsx(ModalWindow
    // @ts-expect-error: title expects string
    , { 
        // @ts-expect-error: title expects string
        title: _jsxs(_Fragment, { children: [t('Translate with AI'), " ", _jsx(Icon, { name: 'sparklesSolid', color: 'primary' })] }), isOpen: true, closeWindow: onCancel, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, width: 'lg', position: 'top', children: _jsxs("section", { className: 'w-full overflow-x-hidden font-sans flex flex-col py-8 first:pt-0 last:pb-0 border-solid border-neutral-200 border-x-0 border-t-0 border-b last:border-b-0', children: [_jsx("div", { className: 'flex flex-col gap-2 mb-8 text-sm', children: _jsx("p", { className: 'text-secondary', children: t('The following options are suggested for AI translation based on the risk of localization causing issues with data collection. Select the assets you want to translate for each locale. Previously translated content is not eligible. AI-assisted translation may be inaccurate, please review before use.') }) }), _jsx("div", { className: 'w-full overflow-x-auto', children: _jsx(AssetLocalePairSelection, { assets: assets, locales: filteredLocales, onSelectionChange: setAssetLocalePair }) })] }) }));
};
