import { useMemo } from 'react';
import { AssetTypes, LocalizationStatus, } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { Locale } from 'libs.nucleus';
import { getRolebyAvailableTo, getTypeByAvailableTo, getVersionStatus } from '../utils/data_utils';
import { toSnakeCase } from '../utils/string_utils';
export const useResourcesPdfData = (study, participantLocales = [], siteLocales = []) => {
    const resources = study.studyResources;
    const _resources = resources?.filter((r) => Boolean(r.files));
    const assets = _resources?.map(({ id }) => ({
        type: AssetTypes.RESOURCES_PDF,
        id,
        version: '1',
    }));
    const locales = Array.from(new Set([...participantLocales, ...siteLocales]));
    const { localizationsMap, isLoading } = useLocalizationCollection(assets);
    const _data = useMemo(() => {
        if (!_resources?.length) {
            return {};
        }
        const data = {};
        _resources.forEach(({ id, title, availableTo, files }) => {
            if (files) {
                const localeData = {};
                if (files.length !== 1) {
                    console.error(new Error('Internal error'), id, title, availableTo, files);
                    return;
                }
                // There should be only ONE file and it's supposed to be the base language file (en-US).
                const resourceId = files[0].id;
                for (const locale of locales) {
                    localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
                }
                const _asset = assets.find((a) => a.id === id);
                const { localizationEntry, localization, } = localizationsMap?.get(_asset) || {};
                const translationWorkbenchId = localization?.getId();
                const role = getRolebyAvailableTo(availableTo);
                const versionStatus = getVersionStatus({
                    localizationEntry,
                    participantLocales,
                    siteLocales,
                    role,
                    translationWorkbenchId,
                    assetType: AssetTypes.RESOURCES_PDF,
                    isLoading,
                });
                data[translationWorkbenchId] = {
                    displayName: title.trim(),
                    translationWorkbenchId,
                    id,
                    path: id,
                    type: getTypeByAvailableTo(availableTo),
                    role,
                    assetType: AssetTypes.RESOURCES_PDF,
                    statuses: localeData,
                    ...versionStatus,
                    asset: {
                        type: AssetTypes.RESOURCES_PDF,
                        id,
                        version: '1',
                        human_id: toSnakeCase(title),
                        resource_id: resourceId,
                        messages: [
                            {
                                namespace: 'study_pdf',
                                messages: { resourceId },
                            },
                        ],
                    },
                };
            }
        });
        return data;
    }, [_resources, localizationsMap, isLoading]);
    return { data: _data, isLoading };
};
