import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, H5 } from 'libs.nucleus';
import { ListInformation, useCountriesOptions } from 'libs.react';
import { getCountryNameFromCode } from '../../utils';
export const WorkspaceSiteDetails = ({ workspaceSite }) => {
    const translate = useLocalizeMessage();
    const countriesOptions = useCountriesOptions();
    const basicInformation = [
        {
            title: translate('Name'),
            description: workspaceSite.name,
        },
        {
            title: translate('Site library ID'),
            description: workspaceSite.number,
        },
        {
            title: translate('Timezone'),
            description: workspaceSite.timezone,
        },
    ];
    const contactInformation = [
        {
            title: translate('Email'),
            description: workspaceSite.email,
        },
        {
            title: translate('Phone'),
            description: workspaceSite.address.phone.map((phone) => `${phone.type}: ${phone.number}`).join(', '),
        },
        {
            title: translate('Country'),
            description: getCountryNameFromCode(workspaceSite.address.countryCode, countriesOptions),
        },
        {
            title: translate('Address'),
            description: workspaceSite.address.addressLine,
        },
        {
            title: translate('City / Town'),
            description: workspaceSite.address.city,
        },
        {
            title: translate('State / Province / Region'),
            description: workspaceSite.address.region,
        },
        {
            title: translate('Zip / Postal code'),
            description: workspaceSite.address.postalCode,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ListInformation, { items: basicInformation, dataTestId: 'basic-information' }), _jsx(H5, { className: 'mb-6 mt-6', children: translate('Contact information') }), _jsx(ListInformation, { items: contactInformation, dataTestId: 'contact-information' })] }));
};
