import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useContext } from 'react';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FlagContext } from 'libs.flags.react';
import { Locale } from 'libs.i18n-core';
import { useLocalizeMessage } from 'libs.nucleus';
import { ReportingCard, ReportingGrid, NumberChart, ReportingChart } from 'libs.react';
import { useStatusData, useAssetsTranslateWithAI } from './translation_report.hooks';
import { GenerateTranslationMultiAction } from '../../components/generate_translation_action';
import { TranslationWorkbenchContext } from '../../contexts';
import { TRANSLATION_MANAGEMENT_FLAGS } from '../../utils';
export const TranslationReport = ({ filteredAssets = {}, filteredLocales, }) => {
    const t = useLocalizeMessage();
    const { isStudyEnglishOnly, studyLocalizationStatus } = useContext(TranslationWorkbenchContext);
    const flagManager = useContext(FlagContext);
    const targetedAiStudyTranslation = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.targetedAiStudyTranslation);
    const { data, backgroundColor, labels, sum, hasNoResults } = useStatusData(filteredAssets, filteredLocales);
    const { assetsTranslateWithAI } = useAssetsTranslateWithAI(filteredAssets);
    const isActionDisabled = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const isImportActionDisabled = isStudyEnglishOnly || isActionDisabled;
    const localesCount = Object.keys(filteredAssets).length
        ? filteredLocales.filter((locale) => Locale.en_US !== locale).length
        : 0;
    const chartData = useMemo(() => ({
        labels,
        datasets: [
            {
                label: t('Status'),
                data,
                backgroundColor,
                borderWidth: 1,
            },
        ],
    }), [data, backgroundColor, labels]);
    return (_jsx(ReportingGrid, { cards: [
            {
                card: (_jsx(ReportingCard, { title: t('Summary'), tooltip: t('Provides total count of all assets and locales within the study.'), children: _jsx(NumberChart, { data: {
                            datasets: [
                                { label: t('Locales'), number: localesCount, numberSize: '7xl' },
                                { label: t('Assets'), number: Object.keys(filteredAssets).length, numberSize: '7xl' },
                            ],
                        } }) })),
                xSpan: 3,
            },
            {
                card: (_jsx(ReportingCard, { title: 'Statuses', tooltip: t('Displays the percentage of each status for the entire study.'), children: _jsx(ReportingChart, { data: chartData, type: 'doughnut', options: {
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            if (hasNoResults) {
                                                return ` ${context.label}`;
                                            }
                                            const percentFormatter = new Intl.NumberFormat(Locale.en_US, {
                                                style: 'percent',
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            });
                                            return ` ${context.label}: ${context.formattedValue} (${percentFormatter.format(context.parsed / sum)})`;
                                        },
                                    },
                                },
                                legend: {
                                    position: 'right',
                                    labels: {
                                        usePointStyle: true,
                                    },
                                },
                            },
                        } }) })),
                xSpan: 3,
            },
            // {
            //   card: (
            //     <ReportingCard
            //       footerAction={<TranslationReportReview />}
            //       title={t('Version reviews')}
            //       tooltip={t('Displays when new activity versions within the library are available for review.')}
            //     >
            //       <NumberChart
            //         data={{
            //           datasets: [
            //             { label: t('Pending'), number: 5, numberSize: '5xl' },
            //             { label: t('New'), number: 3, numberSize: '5xl' },
            //           ],
            //         }}
            //       />
            //     </ReportingCard>
            //   ),
            //   xSpan: 3,
            // },
            ...(targetedAiStudyTranslation
                ? [
                    {
                        card: (_jsx(ReportingCard, { footerAction: _jsx(GenerateTranslationMultiAction, { assets: assetsTranslateWithAI, locales: filteredLocales, hideLocales: [Locale.en_US], isDisabled: isImportActionDisabled }), title: t('Automation suggestions'), tooltip: t('Select suggested assets and locales for AI translation.'), children: _jsx(NumberChart, { data: {
                                    datasets: [
                                        {
                                            label: t('Assets'),
                                            number: Object.keys(assetsTranslateWithAI).length,
                                            numberSize: '7xl',
                                        },
                                        { label: t('Locales'), number: localesCount, numberSize: '7xl' },
                                    ],
                                } }) })),
                        xSpan: 3,
                    },
                ]
                : []),
        ], rowHeight: 'sm' }));
};
