import { PlainDateTime } from 'temporal-luxon';
export const versionNumberToString = (version) => {
    if (typeof version === 'number') {
        return version.toString().split('').join('.');
    }
    else if (typeof version === 'string') {
        // the version number will be of the format rxxx, eg r330
        return version.substring(1).split('').join('.');
    }
    return '';
};
export const getDatesDiff = (firstDate, secondDate) => {
    const milliseconds = firstDate.getTime() - secondDate.getTime();
    return new PlainDateTime(1, 1, 1).add({ milliseconds: Math.abs(milliseconds) });
};
/**
 * Check if two strings match
 * @param str1 The first string
 * @param str2 The second string
 * @param sanitize Whether to sanitize the strings before comparing
 * @returns Whether the strings match or not
 */
export const isSameString = (str1, str2, sanitize = true) => {
    if (!str1 || !str2) {
        return false;
    }
    return sanitize ? str1.toLowerCase().trim() === str2.toLowerCase().trim() : str1 === str2;
};
/**
 * Method to sort an array after running a map function (or similar) that returns an array of objects
 * ie: sortBy(items, 'key')
 *
 * Currently, this function only has support for string and number values
 */
export const sortBy = (items, key) => {
    return items.sort(inlineSortBy(key));
};
/**
 * Returns a function that can be used to sort an array of objects by a specific key
 * This is useful to sort an array after running a map function (or similar) that returns an array of objects
 * ie: items.map(...).sort(inlineSortBy('key'))
 *
 * Currently, this function only has support for string and number values
 */
export const inlineSortBy = (key) => {
    return (itemA, itemB) => {
        const valueA = itemA[key];
        const valueB = itemB[key];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    };
};
