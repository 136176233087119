import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { FlagContext } from 'libs.flags.react';
import { JobTypes } from 'libs.jobs.common';
import { useCreateJob } from 'libs.jobs.react';
import { Button, Icon, useLocalizeMessage } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { TRANSLATION_MANAGEMENT_FLAGS } from '../../utils';
import { GenTranslationModal } from '../gen_translation_modal';
export const GenerateTranslationAction = ({ assetId, hideLocales = [] }) => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const [isGenTranslationModalOpen, openGenTranslationModal, closeGenTranslationModal] = useBooleanState(false);
    const aiTranslationFlag = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.aiTranslationFlag);
    const createJob = useCreateJob();
    const disabled = !assetId;
    return (_jsxs(_Fragment, { children: [aiTranslationFlag && (_jsx(Button, { variant: 'secondary', disabled: disabled, label: translate('Translate with AI'), content: _jsxs("span", { className: 'font-sans m-0 text-sm flex items-center gap-2', children: [_jsx(Icon, { name: 'sparklesSolid', color: 'primary', size: 'sm' }), " ", translate('Translate with AI')] }), onClick: openGenTranslationModal })), isGenTranslationModalOpen && (_jsx(GenTranslationModal, { hideLocales: hideLocales, onDismiss: closeGenTranslationModal, onSubmit: (locales) => {
                    if (!assetId) {
                        console.error('Internal error');
                        return;
                    }
                    for (const locale of locales) {
                        createJob(JobTypes.TRANSLATION__GENERATE_TRANSLATION, { locale, asset_id: assetId });
                    }
                    closeGenTranslationModal();
                } }))] }));
};
