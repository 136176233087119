import { useCallback, useState } from 'react';
/**
 * https://usehooks-ts.com/react-hook/use-copy-to-clipboard
 *
 * Custom hook to copy text to the clipboard.
 *
 * @returns A tuple containing:
 * - `copiedText`: The text that was last copied to the clipboard, or `null` if no text has been copied.
 * - `copy`: A function that takes a string as an argument and attempts to copy it to the clipboard.
 *           Returns `true` if the copy was successful, `false` otherwise.
 *
 * @example
 * const [copiedText, copy] = useCopyToClipboard();
 *
 * const handleCopy = async () => {
 *   const success = await copy('Hello, World!');
 *   if (success) {
 *     ...
 *   } else {
 *     ...
 *   }
 * };
 */
export function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null);
    const copy = useCallback(async (text) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return false;
        }
        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        }
        catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    }, []);
    return [copiedText, copy];
}
