export var AclScope;
(function (AclScope) {
    AclScope["MOM_STUDY_CREATE"] = "mom.study.create";
    AclScope["MOM_STUDY_GET"] = "mom.study.get";
    AclScope["MOM_STUDY_LIST"] = "mom.study.list";
    AclScope["MOM_STUDY_DELETE"] = "mom.study.delete";
    AclScope["MOM_STUDY_DEPLOY_DEV"] = "mom.study.deployDev";
    AclScope["MOM_STUDY_DEPLOY_PROD"] = "mom.study.deployProd";
    AclScope["MOM_STUDY_GET_IAM_POLICY"] = "mom.study.getIamPolicy";
    AclScope["MOM_STUDY_SET_IAM_POLICY"] = "mom.study.setIamPolicy";
    AclScope["MOM_STUDY_UPDATE"] = "mom.study.update";
    AclScope["CONTENT_LIBRARY_CREATE"] = "content.library.create";
    AclScope["CONTENT_LIBRARY_DELETE"] = "content.library.delete";
    AclScope["CONTENT_LIBRARY_GET"] = "content.library.get";
    AclScope["CONTENT_LIBRARY_LIST"] = "content.library.list";
    AclScope["CONTENT_LIBRARY_UPDATE"] = "content.library.update";
    AclScope["CONTENT_LIBRARY_APPROVE"] = "content.library.approve";
    AclScope["IAM_IDENTITY_CREATE"] = "iam.identity.create";
    AclScope["IAM_IDENTITY_GET"] = "iam.identity.get";
    AclScope["IAM_IDENTITY_GET_IAM_POLICY"] = "iam.identity.getIamPolicy";
    AclScope["IAM_IDENTITY_IMPERSONATE"] = "iam.identity.impersonate";
    AclScope["IAM_IDENTITY_LIST"] = "iam.identity.list";
    AclScope["IAM_IDENTITY_SET_IAM_POLICY"] = "iam.identity.setIamPolicy";
    AclScope["IAM_IDENTITY_UPDATE"] = "iam.identity.update";
    AclScope["MOM_ENTITY_CREATE"] = "mom.entity.create";
    AclScope["MOM_ENTITY_GET"] = "mom.entity.get";
    AclScope["MOM_ENTITY_GET_IAM_POLICY"] = "mom.entity.getIamPolicy";
    AclScope["MOM_ENTITY_LIST"] = "mom.entity.list";
    AclScope["MOM_ENTITY_SET_IAM_POLICY"] = "mom.entity.setIamPolicy";
    AclScope["MOM_ENTITY_UPDATE"] = "mom.entity.update";
    AclScope["MOM_ENVIRONMENT_CREATE"] = "mom.environment.create";
    AclScope["MOM_ENVIRONMENT_DELETE"] = "mom.environment.delete";
    AclScope["MOM_ENVIRONMENT_GET"] = "mom.environment.get";
    AclScope["MOM_ENVIRONMENT_GET_IAM_POLICY"] = "mom.environment.getIamPolicy";
    AclScope["MOM_ENVIRONMENT_LIST"] = "mom.environment.list";
    AclScope["MOM_ENVIRONMENT_PROVISION"] = "mom.environment.provision";
    AclScope["MOM_ENVIRONMENT_PROVISION_PROD"] = "mom.environment.provisionProd";
    AclScope["MOM_ENVIRONMENT_SET_IAM_POLICY"] = "mom.environment.setIamPolicy";
    AclScope["MOM_ENVIRONMENT_UPDATE"] = "mom.environment.update";
    AclScope["MOM_PROFILE_CREATE"] = "mom.profile.create";
    AclScope["MOM_PROFILE_DELETE"] = "mom.profile.delete";
    AclScope["MOM_PROFILE_GET"] = "mom.profile.get";
    AclScope["MOM_PROFILE_LIST"] = "mom.profile.list";
    AclScope["MOM_PROFILE_PROVISION"] = "mom.profile.provision";
    AclScope["MOM_PROFILE_UPDATE"] = "mom.profile.update";
    AclScope["MOM_SITE_GET"] = "mom.site.get";
    AclScope["MOM_SITE_LIST"] = "mom.site.list";
    AclScope["MOM_SITE_CREATE"] = "mom.site.create";
    AclScope["MOM_SITE_UPDATE"] = "mom.site.update";
    AclScope["MOM_SITE_DELETE"] = "mom.site.delete";
})(AclScope || (AclScope = {}));
