import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { Dropdown } from 'libs.nucleus';
import { DeviceOptions } from './device_option';
import { selectedDeviceAtom } from './device_selector_store';
import { DeviceSelectorWrapper } from './styles';
export const DeviceSelector = ({ onDeviceSelect, devices = DeviceOptions, defaultDevice = DeviceOptions.iphonese, showDimensions = false, shouldInitialize = true, width, }) => {
    const [selectedDevice, setSelectedDevice] = useAtom(selectedDeviceAtom);
    useEffect(() => {
        if (shouldInitialize) {
            const firstOption = Object.keys(devices)[0];
            setSelectedDevice(defaultDevice || devices[firstOption]);
        }
    }, [shouldInitialize, defaultDevice]);
    const dropItems = useMemo(() => Object.keys(devices).map((device) => ({
        id: devices[device].device,
        content: showDimensions ? (_jsxs("div", { className: 'flex gap-2 w-full justify-between', children: [_jsx("span", { children: devices[device].device }), _jsxs("small", { className: 'text-secondary', children: [devices[device].width, " x ", devices[device].height] })] })) : (devices[device].device),
        onClick: () => {
            setSelectedDevice(devices[device]);
            onDeviceSelect === null || onDeviceSelect === void 0 ? void 0 : onDeviceSelect(devices[device]);
        },
    })), [onDeviceSelect, setSelectedDevice]);
    return (_jsx(DeviceSelectorWrapper, { children: _jsx(Dropdown, { items: dropItems, label: selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.device, width: width }) }));
};
