import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage, Link, Spinner, Text } from 'libs.nucleus';
import { ListInformation, useBooleanState, useDateTime } from 'libs.react';
import { getParticipantNextVisitWindow, isParticipantAuthenticated, isParticipantDeactivated, } from '../../helpers/participants.helpers';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
import { EParticipantInviteStatus } from '../../types/participants.type';
import { ParticipantDeactivationModal } from '../participant_deactivation/participant_deactivation_modal.component';
import { ParticipantInviteModal } from '../participant_form/participant_invite_modal.component';
import { ResetPassword } from '../reset_password';
export const ParticipantDetails = ({ participant, participantDetailsLoading }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { parseDateTimeShort } = useDateTime();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    const [isDeactivationModalOpen, setIsDeactivationModalOpen, setIsDeactivationModalClosed] = useBooleanState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen, setIsResetPasswordModalClose] = useBooleanState(false);
    const { environment } = useCurrentEnvironment();
    const org = useOrgContext();
    const queryClient = useQueryClient();
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const refreshDetailsData = () => {
        queryClient.invalidateQueries({
            queryKey: ['participantDetails', participant._id],
        });
    };
    const onDeactivationModalClose = () => {
        setIsDeactivationModalClosed();
        refreshDetailsData();
    };
    const onInviteModalClose = () => {
        setIsInviteModalClose();
        refreshDetailsData();
    };
    const detailItems = [
        {
            title: translate('Language'),
            description: participant.c_locale ? I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, false) : null,
            dataTestId: 'participant-details-language',
        },
        {
            title: translate('Enrolled'),
            description: participant.c_enrollment_date && parseDateTimeShort(participant.c_enrollment_date),
            dataTestId: 'participant-details-enrolled',
        },
        ...(org.c_no_pii
            ? [
                {
                    title: translate('Username'),
                    description: participant.c_username,
                    ...(isParticipantAuthenticated(participant) && {
                        actionContent: (_jsx(Link, { "data-testid": 'reset-password', href: '#', onClick: setIsResetPasswordModalOpen, children: translate('Reset Password') })),
                    }),
                    dataTestId: 'participant-details-username',
                },
            ]
            : [{ title: translate('Email'), description: participant.c_email, dataTestId: 'participant-details-email' }]),
        ...(!isParticipantDeactivated(participant)
            ? [
                {
                    title: translate('Invite Code'),
                    description: participant.c_invite === EParticipantInviteStatus.INVITED ? (_jsxs("div", { children: [_jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('Study code'), ": ", studyCode] }), _jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('Invite code'), ": ", participant.c_access_code] })] })) : (_jsx(Text, { size: 'sm', className: 'capitalize', children: participant.c_invite })),
                    actionContent: (_jsx(Link, { "data-testid": 'add-view-invite', href: '#', onClick: setIsInviteModalOpen, children: participant.c_invite === EParticipantInviteStatus.INVITED
                            ? translate('View')
                            : translate('New Invite') })),
                    dataTestId: 'participant-details-invite-code',
                },
            ]
            : []),
        {
            title: translate('Cohort'),
            description: participant.c_visit_schedule?.c_name,
            dataTestId: 'participant-details-cohort',
        },
        {
            title: translate('Status'),
            description: participant.c_status,
            dataTestId: 'participant-details-status',
        },
        {
            title: translate('Next Visit'),
            description: getParticipantNextVisitWindow(participant),
            dataTestId: 'participant-details-next-visit',
        },
        {
            title: translate('Created'),
            description: parseDateTimeShort(participant.created),
            //  If participant doesn't have an account or is deactivated, don't show the deactivate button
            ...(isParticipantAuthenticated(participant) &&
                !isParticipantDeactivated(participant) && {
                actionContent: (_jsx(Link, { "data-testid": 'deactivate-action', href: '#', onClick: setIsDeactivationModalOpen, children: translate('Deactivate') })),
            }),
            dataTestId: 'participant-details-created',
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: participant, onClose: onInviteModalClose, onDone: onInviteModalClose }), _jsx(ParticipantDeactivationModal, { isModalOpen: isDeactivationModalOpen, participant: participant, onClose: onDeactivationModalClose, onDone: onDeactivationModalClose }), _jsx(ResetPassword, { isModalOpen: isResetPasswordModalOpen, onClose: setIsResetPasswordModalClose, participant: participant }), _jsx("div", { className: 'flex flex-col gap-8', children: _jsx(ListInformation, { dataTestId: 'participant-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }));
};
