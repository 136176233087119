import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Input } from 'libs.nucleus';
import { useOrgContext } from '../../../hooks/use_org_context';
const FIELD_NAME = 'email';
export const EmailField = ({ form, errors, onFieldChange, isFieldtouched }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    //  If org is a no pii org, dont show email field
    if (org?.c_no_pii) {
        return null;
    }
    const fieldLabel = translate('Email');
    return (_jsx(Input, { dataTestId: 'caregiver-email', id: FIELD_NAME, name: FIELD_NAME, label: fieldLabel, onChange: onFieldChange, value: form?.[FIELD_NAME] ?? '', width: 'lg', description: translate('Caregiver acknowledges email address may be used for study-related communications.'), errorMessage: errors?.[FIELD_NAME] && isFieldtouched(FIELD_NAME) ? errors?.[FIELD_NAME] : undefined, hasError: !!(errors?.[FIELD_NAME] && isFieldtouched(FIELD_NAME)), "data-testid": 'caregiver-email-input' }));
};
