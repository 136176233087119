import { jsx as _jsx } from "react/jsx-runtime";
import { RadioGroup, RadioGroupOption } from 'libs.nucleus';
export const SelectionCard = ({ description, label, onClick, selectedValue, value, }) => {
    const isSelected = selectedValue === value;
    return (_jsx("section", { className: `p-6 cursor-pointer border border-solid rounded-lg w-1/2 ${isSelected ? 'bg-purple-50 border-purple-600 border-[1.5px]' : 'hover:bg-neutral-50'}`, onClick: () => onClick(value), children: _jsx(RadioGroup, { onChange: onClick, value: selectedValue, children: _jsx(RadioGroupOption, { option: {
                    label,
                    description,
                    value,
                } }) }) }));
};
