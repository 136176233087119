import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes } from 'libs.localization.common';
import { useLocalization, useLocalizationExport } from 'libs.localization.react';
import { Locale, useLocalizeMessage, Spinner, Text } from 'libs.nucleus';
import { DeviceSelector, selectedDeviceAtom } from 'libs.preview';
import { PageHeader } from 'libs.react';
import { AIGeneratedBanner } from '../../components/ai_generated_banner/AI_generated_banner.component';
import { AssetsInfoPanel } from '../../components/assets_info_panel';
import { BuildVersion } from '../../components/locale_matrix/build_version.component';
import { ActivityReviewScreenList } from '../../components/review_screenlist';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLibraryDetails } from '../../hooks';
import { deviceList } from '../../utils/device_list';
export const ActivityReviewView = ({ assetId, studyId }) => {
    const translate = useLocalizeMessage();
    const { locale = Locale.en_US } = useParams();
    const [selectedDevice] = useAtom(selectedDeviceAtom);
    const { studyLocalizationStatus, libraryAssets } = useContext(TranslationWorkbenchContext);
    const isStudyinDraft = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const { logEvent } = useContext(FirebaseAppContext);
    const { isLoading, data: library } = useLibraryDetails(assetId);
    // autobuild activities return in data.data.data.activity vs ecoa return in data.data.data format
    // TODO remove autobuild activities return in data.data.data.activity
    const resource = library?.data.data.data.activity || library?.data.data.data;
    const buildVersion = library?.data.data.version;
    const _activity = Object.values(libraryAssets).find((asset) => asset.id === assetId);
    const resourceDisplayname = resource?.short_name;
    const { exportLocalization, isFetching } = useLocalizationExport();
    const { localizationEntry, localization } = useLocalization(resource ? { activity: resource } : undefined);
    const localeVersion = localizationEntry?.supported_locales?.[locale]?.version;
    const currentVersionStatus = _activity?.currentVersionStatus?.[locale];
    // @TODO check pathname and add correct breadcrumb study vs library
    const breadcrumbPages = useMemo(() => [
        { name: translate('Translations'), path: `/studies/${studyId}/build/translations` },
        {
            name: resourceDisplayname || '',
            path: `/studies/${studyId}/build/translations/${assetId}?assetType=${AssetTypes.ACTIVITY}`,
        },
        { name: locale || '', path: `/studies/${studyId}/build/translations/${assetId}/review/${locale}` },
    ], [studyId, assetId, resourceDisplayname]);
    const info = [
        { title: translate('Short Name'), description: resource?.short_name || '' },
        {
            title: translate('Display Name'),
            description: resource?.full_name || '',
        },
        {
            title: translate('Build version'),
            description: _jsx(BuildVersion, { version: buildVersion }),
        },
    ];
    const defaultDevice = useMemo(() => {
        let defaultDevice;
        const type = resource?.activity_metadata?.instrument_type;
        switch (type) {
            case 'clinro':
                defaultDevice = deviceList.desktopSmall;
                break;
            case 'pro':
                defaultDevice = deviceList.iosSmall;
                break;
            default:
                defaultDevice = deviceList[Object.keys(deviceList)[0]];
        }
        return defaultDevice;
    }, [resource]);
    const handleExport = useCallback(() => {
        if (resource && localization) {
            const viewport = Object.entries(deviceList).find(([, value]) => value.device === selectedDevice.device)?.[0];
            exportLocalization({
                resources: ['screens'],
                locales: [locale],
                ...(viewport && { viewport }),
                assets: { [localization.getId()]: [`${locale}~${currentVersionStatus?.version}`] },
            });
            logEvent('translations_screen_report_downloaded', {
                context: 'study',
                type: AssetTypes.ACTIVITY,
                id: assetId,
                locale,
            });
        }
    }, [resource, locale, selectedDevice, currentVersionStatus, localization]);
    const headerOptions = [
        {
            name: translate('Export'),
            onClick: () => handleExport(),
            variant: 'primary',
            disabled: isStudyinDraft || isFetching,
            isLoading: isFetching,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: locale, breadcrumbPages: breadcrumbPages, options: headerOptions }), _jsx(AIGeneratedBanner, { status: currentVersionStatus?.status, origin: currentVersionStatus?.origin }), _jsxs("div", { className: 'flex flex-col gap-6', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(AssetsInfoPanel, { listItems: info, locale: locale, title: translate('Activity Info'), version: localeVersion, localizationVersion: currentVersionStatus }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Text, { size: 'sm', children: translate('Device size') }), _jsx(DeviceSelector, { devices: deviceList, defaultDevice: defaultDevice, showDimensions: true, width: 'lg' })] })] }), resource && (_jsx(ActivityReviewScreenList, { activity: resource, localeVersion: currentVersionStatus?.version || Number(localeVersion) }))] }));
};
