import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { AxonAccountContext } from '../axon_account';
export const SiteContext = createContext({
    isLoading: true,
    site: null,
    indexedSites: null,
    sites: [],
    setSelectedSite: () => { },
});
export const SiteProvider = ({ children }) => {
    const match = useMatch('/site/study/:studyId/*');
    const { account } = useContext(AxonAccountContext);
    const { environment } = useCurrentEnvironment(match?.params?.studyId);
    const [selectedSite, setSelectedSite] = useState(null);
    const getSites = async () => {
        if (!environment || !account) {
            return;
        }
        const url = `/v2/account/${account._id}/c_sites`;
        const { data: response } = await getApiInstance(environment).get(url);
        setSelectedSite(response?.data?.[0] || null);
        return response.data || [];
    };
    const { data: sites = [], isLoading } = useQuery({
        queryKey: ['getSite', environment?.id?.toString()],
        queryFn: getSites,
        enabled: !!environment && !!account,
        refetchOnWindowFocus: false,
    });
    const value = useMemo(() => {
        const indexedSites = sites?.reduce((acc, site) => {
            acc[site._id] = site;
            return acc;
        }, {});
        return {
            isLoading,
            site: selectedSite,
            sites,
            indexedSites,
            setSelectedSite,
        };
    }, [selectedSite, isLoading]);
    return _jsx(SiteContext.Provider, { value: value, children: children });
};
