import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { LocalizationStatus } from 'libs.localization.common';
import { useLocalizeMessage, ModalWindow, Select, Spinner, Text } from 'libs.nucleus';
import { WorkflowInformation } from './workflow_information.component';
export const LocaleStatusChangeModal = ({ onModalClose, onHandleStatus, status, isChanging, }) => {
    const t = useLocalizeMessage();
    const statusOptions = useMemo(() => [
        {
            value: LocalizationStatus.IN_PROGRESS,
            label: t('In Progress'),
        },
        {
            value: LocalizationStatus.IN_REVIEW,
            label: t('In Review'),
        },
        {
            value: LocalizationStatus.READY,
            label: t('Ready'),
        },
    ], []);
    const [currentStatusOption, setCurrentStatusOption] = useState(statusOptions.find((option) => option.value === status) || statusOptions[0]);
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
        disabled: isChanging,
    };
    const doneButton = {
        label: t('Change status'),
        content: isChanging ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm' }) })) : undefined,
        disabled: isChanging,
        onClick: () => {
            onHandleStatus(currentStatusOption.value);
        },
    };
    const displayStatus = statusOptions.find((option) => option.value === status);
    return (_jsx(ModalWindow, { title: t('Edit activity translation status'), isOpen: true, closeWindow: onModalClose, footerPrimaryActionButton: doneButton, footerSecondaryActionButtons: [closeButton], position: 'top', width: 'full', children: _jsxs("div", { className: 'flex flex-col gap-4 mt-10', children: [_jsx(WorkflowInformation, {}), _jsxs("div", { className: 'flex py-6', children: [_jsx(Text, { size: 'sm', className: 'font-semibold w-56', children: t('Current status:') }), _jsx("div", { className: 'flex-[2_2_0%]', children: _jsx(Text, { size: 'sm', className: 'text-secondary', dataTestId: 'current-translation-status', children: displayStatus?.label || t('Not Started') }) })] }), _jsxs("div", { className: 'flex items-center', children: [_jsx(Text, { size: 'sm', className: 'font-semibold w-56', children: t('New status:') }), _jsx(Select, { value: currentStatusOption, required: true, options: statusOptions, onChange: setCurrentStatusOption, width: 'lg', dataTestId: 'translation-status-options' })] })] }) }));
};
