import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FileUploader, useLocalizeMessage, ModalDialog, Spinner, } from 'libs.nucleus';
export const ImportModal = ({ onModalClose, onHandleImport, isLoading = false }) => {
    const t = useLocalizeMessage();
    const [fileStates, setFileStates] = useState({});
    const [files, setFiles] = useState({});
    const onModalWindowClose = () => {
        onModalClose();
    };
    const onDoneClick = () => {
        onHandleImport({ files });
    };
    const onFileDelete = (_, deleteIdx, onSuccess) => {
        const newFileStates = { ...fileStates };
        delete newFileStates[deleteIdx];
        setFileStates(newFileStates);
        const newFiles = { ...files };
        delete newFiles[deleteIdx];
        setFiles(newFiles);
        onSuccess();
    };
    const handleFileUpload = (files) => {
        setFileStates(Object.keys(files).reduce((acc, key) => {
            acc[key] = { type: 'success' };
            return acc;
        }, {}));
        setFiles(files);
    };
    const doneButton = {
        label: t('Import'),
        onClick: onDoneClick,
        disabled: Object.keys(files).length === 0,
        content: isLoading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: 'alt' }) })) : undefined,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalWindowClose,
        disabled: isLoading,
    };
    return (_jsx(ModalDialog, { title: t('Import translations'), isOpen: true, closeDialog: onModalWindowClose, actionButtons: [doneButton, closeButton], width: 'base', body: _jsx(FileUploader, { label: t('Select translation files'), accept: '.json,.zip', multiple: true, onFileChange: handleFileUpload, onFileDelete: onFileDelete, fileStates: fileStates, description: t('Upload individual or multiple files using .json or .zip formats.'), buttonLabel: t('Upload') }) }));
};
