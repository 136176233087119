import { useQuery, keepPreviousData } from '@tanstack/react-query';
import querystring from 'query-string';
import { useContext, useState } from 'react';
import { AuthContext, useApiClient, ApiClientService } from 'libs.react';
// todo: add search functionality
export const useWorkspaceSites = ({ pageIndex = 0, pageSize = 10 }) => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const [error, setError] = useState();
    const [pagination, setPagination] = useState({ pageSize, pageIndex });
    const [totalPages, setTotalPages] = useState(0);
    // const [search, setSearch] = useState('');
    const queryKey = [
        'entities',
        entityId.toString(),
        'sites',
        {
            pageIndex: pagination.pageIndex.toString(),
            pageSize: pagination.pageSize.toString(),
        },
    ];
    // const debouncedSearchChange = useCallback(
    //   debounce((value = '') => {
    //     setSearch(value as string);
    //   }, DEFAULT_DEBOUNCE_TIME),
    //   []
    // );
    const fetchSites = async () => {
        try {
            const queryObject = {
                limit: pagination.pageSize.toString(),
                page: (pagination.pageIndex + 1).toString(),
                // ...(search && { search }),
            };
            const queryParams = querystring.stringify(queryObject);
            const { data: response } = await momClient.get(`/v1/entities/${entityId}/sites?${queryParams}`);
            const totalPages = Math.ceil(response.total / pagination.pageSize);
            setTotalPages(totalPages);
            return response?.data || [];
        }
        catch (error) {
            setError(error);
        }
    };
    const { data: workspaceSites, isLoading } = useQuery({
        queryKey,
        queryFn: fetchSites,
        placeholderData: keepPreviousData,
    });
    return {
        isLoading,
        error,
        workspaceSites,
        pagination,
        totalPages,
        onPaginationChange: setPagination,
        // onSearchChange: debouncedSearchChange,
    };
};
