import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ModalDialog, useLocalizeMessage } from 'libs.nucleus';
import { useBooleanState, useToastNotification } from 'libs.react';
import { getApiErrorMessage, getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useSiteContext } from '../../hooks/use_site_context';
export const ResetPassword = ({ isModalOpen, participant, onClose }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const [newPassword, setNewPassword] = useState(null);
    const [isNewPasswordModalOpen, setIsNewPasswordModalOpen, setIsNewPasswordModalClose] = useBooleanState(false);
    const onHandleResetPassword = async () => {
        if (!environment) {
            throw new Error('Environment not defined');
        }
        try {
            const response = await getApiInstance(environment).post(`/routes/c_site/${site._id}/password_reset/${participant._id}`);
            addNotification({
                title: '',
                subtitle: translate('Password reset successfully'),
                type: 'success',
            });
            setNewPassword(response.data.data.newPassword);
            setIsNewPasswordModalOpen();
        }
        catch (error) {
            addNotification({
                title: '',
                subtitle: getApiErrorMessage(error) ?? translate('An error occured when resetting the password'),
                type: 'error',
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalDialog, { title: translate('Reset password'), body: _jsx("div", { className: 'flex', children: translate("This will reset the participant's password and generate a temporary password. This cannot be undone.") }), width: 'lg', actionButtons: [
                    {
                        label: translate('Reset'),
                        onClick: onHandleResetPassword,
                    },
                    {
                        label: translate('Cancel'),
                        onClick: () => { },
                    },
                ], isOpen: isModalOpen, closeDialog: onClose, willCloseOnOutsideClickAndEscapeKeyPress: false }), isNewPasswordModalOpen && (_jsx(ModalDialog, { title: translate('Reset password'), body: _jsxs("div", { className: '', children: [_jsx("div", { className: 'pb-2', children: translate('Please share the following temporary password with the participant:') }), _jsx("div", { className: 'nucleus-px-6 px-1 bg-neutral-100 w-fit', "data-testid": 'new-password', children: _jsx("span", { className: 'nucleus-text-h1 nucleus-font-light', children: newPassword }) })] }), width: 'lg', actionButtons: [
                    {
                        label: translate('Done'),
                        onClick: () => setNewPassword(null),
                    },
                ], isOpen: isNewPasswordModalOpen, closeDialog: setIsNewPasswordModalClose, willCloseOnOutsideClickAndEscapeKeyPress: false }))] }));
};
