export var CaregiverStatus;
(function (CaregiverStatus) {
    CaregiverStatus["ENABLED"] = "enabled";
    CaregiverStatus["DISABLED"] = "disabled";
    CaregiverStatus["PENDING"] = "pending";
})(CaregiverStatus || (CaregiverStatus = {}));
export const getCaregiverStatus = (caregiver, caregiverAssignments = []) => {
    const isInviteCompleted = caregiver.c_invite_status === 'completed';
    const isActiveCaregiver = caregiver.c_caregiver_active;
    const isAssignedCaregiver = caregiverAssignments.includes(caregiver.c_public_user._id);
    if (isInviteCompleted && isActiveCaregiver && isAssignedCaregiver) {
        return CaregiverStatus.ENABLED;
    }
    if (isInviteCompleted && !isActiveCaregiver && !isAssignedCaregiver) {
        return CaregiverStatus.DISABLED;
    }
    return CaregiverStatus.PENDING;
};
export const getCaregiverDisplayNameCount = (displayName = '') => Number.parseInt(displayName.split('-').pop() ?? '');
export const formatCaregiverDisplayName = (displayName = '') => {
    const count = getCaregiverDisplayNameCount(displayName);
    return `Caregiver ${count}`;
};
