import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { ApiClientService, useApiClient } from 'libs.react';
export const useLocalizedBundle = (isCustomActivity = false) => {
    const { firebaseToken } = useContext(FirebaseAuthContext);
    const { post } = useApiClient(ApiClientService.CLOUD, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${firebaseToken || ''}` },
    });
    const url = isCustomActivity ? '/custom_ecoa/v2/localized' : '/instrument_bundler/v2/localized';
    return useMutation({
        mutationKey: ['instrument_bundler', 'localized'],
        mutationFn: ({ resourceId, locales }) => {
            return post(url, { resourceId, localesWithVersion: locales });
        },
        retry: false,
    });
};
