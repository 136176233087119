import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { usePrevious } from '../use_previous';
export const useForm = ({ initialValues }) => {
    const formFieldsTouchedRef = useRef([]);
    const [form, setForm] = useState(initialValues);
    const prevInitialValues = usePrevious(initialValues);
    /**
     * update form fields value if initialValues change
     */
    useEffect(() => {
        if (prevInitialValues !== undefined && !isEqual(initialValues, prevInitialValues)) {
            setForm(initialValues);
        }
    }, [initialValues]);
    const setFieldTouched = (field) => {
        if (!formFieldsTouchedRef.current.includes(field)) {
            formFieldsTouchedRef.current.push(field);
        }
    };
    const onFieldChange = (event) => {
        const { name, value } = event.target;
        setForm((prev) => ({ ...prev, [name]: value }));
        setFieldTouched(name);
    };
    const onSelectFieldChange = (value, name) => {
        setForm((prev) => ({ ...prev, [name]: value }));
        setFieldTouched(name);
    };
    const isFieldtouched = (field) => {
        return formFieldsTouchedRef.current.includes(field);
    };
    const resetForm = () => {
        setForm(initialValues);
        formFieldsTouchedRef.current = [];
    };
    return {
        form,
        onFieldChange,
        onSelectFieldChange,
        isFieldtouched,
        resetForm,
    };
};
