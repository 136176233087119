import { useMemo } from 'react';
import { AssetTypes, LocalizationStatus, } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { Locale } from 'libs.nucleus';
import { useResourcesLoader } from './use_resources_loader.hook';
import { getRolebyAvailableTo, getTypeByAvailableTo, getVersionStatus } from '../utils/data_utils';
import { toSnakeCase } from '../utils/string_utils';
export const useResourcesData = (study, participantLocales = [], siteLocales = []) => {
    const resources = study.studyResources;
    const _resources = resources?.filter((r) => Boolean(r.contentId));
    const assets = _resources?.map(({ id }) => ({
        type: AssetTypes.RESOURCES,
        id,
        version: '1',
    }));
    const locales = Array.from(new Set([...participantLocales, ...siteLocales]));
    const contentIds = _resources.map((r) => r.contentId) || [];
    const resourcesData = useResourcesLoader(contentIds);
    const { localizationsMap, isLoading } = useLocalizationCollection(assets);
    const _data = useMemo(() => {
        if (!resources?.length || !resourcesData.data.length || resourcesData.pending) {
            return {};
        }
        const data = {};
        resources.forEach(({ id, title, availableTo, contentId }) => {
            if (contentId) {
                const localeData = {};
                for (const locale of locales) {
                    localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
                }
                const resourceData = resourcesData.data.find((r) => r.id === contentId);
                const _asset = assets.find((a) => a.id === id);
                const { localizationEntry, localization, } = localizationsMap?.get(_asset) || {};
                const translationWorkbenchId = localization?.getId();
                const role = getRolebyAvailableTo(availableTo);
                const versionStatus = getVersionStatus({
                    localizationEntry,
                    participantLocales,
                    siteLocales,
                    role,
                    translationWorkbenchId,
                    assetType: AssetTypes.RESOURCES,
                    isLoading,
                });
                data[translationWorkbenchId] = {
                    displayName: title.trim(),
                    translationWorkbenchId,
                    id,
                    path: id,
                    type: getTypeByAvailableTo(availableTo),
                    role,
                    assetType: AssetTypes.RESOURCES,
                    statuses: localeData,
                    ...versionStatus,
                    asset: {
                        type: AssetTypes.RESOURCES,
                        id,
                        version: '1',
                        human_id: toSnakeCase(title),
                        resource_id: contentId,
                        messages: [
                            {
                                namespace: id,
                                messages: {
                                    value: resourceData.data.content,
                                },
                            },
                        ],
                        preview: {
                            engine: 'react',
                            id: AssetTypes.RESOURCES,
                            default_viewport: availableTo === 'site_only' ? 'iPadLandscape' : 'iosSmall',
                            info: {
                                display_name: title,
                                list: [],
                            },
                        },
                    },
                };
            }
        });
        return data;
    }, [resourcesData, localizationsMap, isLoading]);
    return { data: _data, isLoading };
};
