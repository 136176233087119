import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { SectionCard, useBooleanState } from 'libs.react';
import { ParticipantCaregiversList } from './participant_caregivers_list.componet';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { CaregiverFormModal } from '../caregiver_form/caregiver_form_modal.component';
import { CaregiversInviteModal } from '../caregiver_form/caregiver_invite_modal.component';
export const ParticipantCaregivers = ({ participant, participantDetailsLoading, }) => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const [isCaregiverFormOpen, setCaregiverFormOpen, setCaregiverFormClose] = useBooleanState();
    const [isCaregiverInviteOpen, setCaregiverInviteOpen, setCaregiverInviteClose] = useBooleanState();
    const [caregiversToInvite, setCaregiversToInvite] = useState([]);
    const queryClient = useQueryClient();
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    const handleView = (caregiver) => {
        setCaregiversToInvite([caregiver]);
        setCaregiverInviteOpen();
    };
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (!participant.c_caregiver_relationship || isEmpty(participant.c_caregiver_relationship?.c_caregivers_info)) {
        return (_jsxs(_Fragment, { children: [_jsx(SectionCard, { children: _jsxs("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: [_jsx("p", { "data-testid": 'empty-state-message', className: 'mb-8 text-sm text-secondary', children: translate('No caregivers have been added.') }), _jsx(Button, { label: translate('Add caregiver'), variant: 'primary', onClick: setCaregiverFormOpen, dataTestId: 'add-caregiver-empty-state-button' })] }) }), _jsx(CaregiverFormModal, { participant: participant, isModalOpen: isCaregiverFormOpen, onClose: setCaregiverFormClose, onView: handleView, dataTestId: 'add-caregiver-empty-state-modal-window' })] }));
    }
    return (_jsxs("div", { className: 'flex flex-col', children: [_jsx("div", { className: 'flex justify-end mb-4', children: _jsx(Button, { label: translate('Add caregiver'), variant: 'primary', onClick: setCaregiverFormOpen, dataTestId: 'add-caregiver-button' }) }), _jsx(ParticipantCaregiversList, { onView: handleView, studyCode: studyCode, participant: participant }), _jsx(CaregiverFormModal, { participant: participant, isModalOpen: isCaregiverFormOpen, onClose: setCaregiverFormClose, onView: handleView, dataTestId: 'add-caregiver-modal-window' }), _jsx(CaregiversInviteModal, { isModalOpen: isCaregiverInviteOpen, caregivers: caregiversToInvite, onClose: setCaregiverInviteClose, participant: participant })] }));
};
