import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { SideNav, Spinner, Card, Icon, Text, useLocalizeMessage, Link } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { AxonAccountContext } from '../../contexts/axon_account';
import { OrgContext } from '../../contexts/org';
import { SiteContext } from '../../contexts/site';
import { useCurrentEnvironment } from '../../hooks/use_current_environment/use_current_environment.hook';
import { useNavigation } from '../../hooks/use_navigation';
import { SiteSelector } from '../site_selector';
export const SitesWrapper = () => {
    const { pathname } = useLocation();
    const { environment } = useCurrentEnvironment();
    const { account } = useContext(AxonAccountContext);
    const { site, sites } = useContext(SiteContext);
    const { org } = useContext(OrgContext);
    const studyCardProps = useMemo(() => {
        return { protocolNumber: environment?.studyId?.toString() || '', studyName: environment?.name || '' };
    }, [environment?.name, environment?.studyId]);
    const { navItems } = useNavigation();
    const translate = useLocalizeMessage();
    const [isModalOpen, setIsModalOpen, setIsModalClosed] = useBooleanState(false);
    return (_jsxs("div", { className: 'flex grow overflow-hidden', children: [_jsxs("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: [_jsx(SideNav, { activeLinkPath: pathname, items: navItems, LinkComponent: RouterLink, studyCard: studyCardProps }), isModalOpen && _jsx(SiteSelector, { isModalOpen: isModalOpen, setIsModalClosed: setIsModalClosed }), _jsx("div", { className: 'mx-2 mb-2', children: _jsx(Card, { children: _jsxs("div", { className: 'flex flex-wrap w-52 p-2.5', children: [_jsx("div", { children: _jsx(Icon, { name: 'navSites' }) }), !site ? (_jsx("div", { className: 'pl-20', children: _jsx(Spinner, { wrapper: 'none', size: 'sm' }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: 'pl-1.5', style: { flex: '1 1 80px' }, children: _jsx(Text, { size: 'sm', dataTestId: 'site-name', children: site?.c_name }) }), _jsx("div", { className: 'pl-1.5', style: { flex: '1 0 180px', paddingLeft: '30px' }, children: _jsxs(Text, { size: 'sm', dataTestId: 'site-number', children: [translate('ID'), ":", site?.c_number] }) }), sites.length > 1 && (_jsx("div", { style: { flex: '1 0 180px', paddingLeft: '30px' }, children: _jsx(Link, { to: '', "data-testid": 'change-site', onClick: setIsModalOpen, children: _jsx(Text, { size: 'sm', children: translate('Change site') }) }) }))] }))] }) }) })] }), _jsx("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: !environment || !account || !site || !org ? _jsx(Spinner, { wrapper: 'full' }) : _jsx(Outlet, {}) })] }));
};
