import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Text, Tooltip } from 'libs.nucleus';
const CARD_WIDTH_MAP = {
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    base: 'max-w-md',
    lg: 'max-w-lg',
    full: 'flex-1',
};
const CARD_HEIGHT_MAP = {
    xs: 'h-80',
    sm: 'h-96',
    base: 'h-[28rem]',
    lg: 'h-[32rem]',
    full: '',
};
export const ReportingCard = ({ title, tooltip, headerAction, footerAction, children, width = 'full', height = 'full', className = '', }) => (_jsxs("div", { className: `flex flex-col w-full bg-white rounded-md shadow-sm border border-neutral-200 
      ${CARD_WIDTH_MAP[width]} ${CARD_HEIGHT_MAP[height]} ${className}`, children: [(title || headerAction) && (_jsxs("div", { className: 'flex flex-row border-b-1 border-neutral-100 p-2', children: [_jsxs("div", { className: 'flex flex-1 items-center', children: [title && typeof title === 'string' ? (_jsx(Text, { size: 'sm', className: 'text-text-primary', children: title })) : (title), tooltip && (_jsx("div", { className: 'ml-2 [&>button]:flex', children: _jsx(Tooltip, { title: tooltip, placement: 'bottom', children: _jsx("div", { className: 'flex items-center', children: _jsx(Icon, { name: 'info', size: 'sm', color: 'gray' }) }) }) }))] }), headerAction && _jsx("div", { className: 'flex', children: headerAction })] })), _jsx("div", { className: 'flex flex-row flex-1 p-4 overflow-auto', children: children }), footerAction && _jsx("div", { className: 'flex justify-center p-4', children: footerAction && footerAction })] }));
