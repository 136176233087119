import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { differenceInCalendarYears } from 'date-fns';
import { useContext } from 'react';
import { Instant, Now } from 'temporal-luxon';
import { DateTimeShort, TimeShort } from '@medable/i18n-core';
import { Button, LocaleContext, useLocalizeMessage, Icon } from 'libs.nucleus';
import { SiteIcon } from '../../svgIcons/site.icon';
import { UserIcon } from '../../svgIcons/user.icon';
export const AvailableActivities = ({ visit, siteActivity, participantActivity, setVisitActivity, setIsActivityModalOpen, isActivityDisabled, isSiteActivity, showStartButton, }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    let timeRange = null;
    if (!isSiteActivity && participantActivity) {
        //  Issues: https://jira.devops.medable.com/browse/SSP-1327
        //  To prevent showing period dates that end in like 2500 (study end date that is like forever)
        //  We check if the period is more than 100 years, if so we dont show the end date else we show it
        const periodInYears = differenceInCalendarYears(participantActivity?.c_end, participantActivity?.c_start);
        if (periodInYears <= 100) {
            timeRange = new TimeShort(currentLocale).formatRange(Instant.fromEpochMilliseconds(new Date(participantActivity.c_start).getTime()).toZonedDateTimeISO(Now.timeZone()), Instant.fromEpochMilliseconds(new Date(participantActivity.c_end).getTime()).toZonedDateTimeISO(Now.timeZone()));
        }
        else {
            timeRange = new DateTimeShort(currentLocale).format(Instant.fromEpochMilliseconds(participantActivity?.c_start ? new Date(participantActivity?.c_start).getTime() : 0).toZonedDateTimeISO(Now.timeZone()));
        }
    }
    const onHandleActivityStart = () => {
        if (siteActivity) {
            setVisitActivity({
                visit,
                activity: siteActivity,
            });
            setIsActivityModalOpen();
        }
    };
    return (_jsxs("section", { className: `grid grid-cols-3 grid-rows-1 gap-4 w-full bg-white border border-gray-200 mb-2.5 rounded-md px-6 py-4 ${isActivityDisabled ? 'opacity-50' : ''}`, "data-testid": isSiteActivity
            ? `site-activity-${siteActivity?.c_group_task.c_assignment._id}`
            : `participant-activity-${participantActivity?.c_task._id}`, children: [_jsxs("div", { className: 'col-span-2', children: [_jsx("span", { className: 'pr-2.5', children: isSiteActivity ? siteActivity?.c_group_task.c_assignment.c_name : participantActivity?.c_task.c_name }), isSiteActivity ? (_jsxs("div", { className: 'flex items-center', children: [_jsx(SiteIcon, { className: 'flex-1 text-green-500' }), _jsx("span", { className: 'px-1.5 text-xs text-green-500', children: translate('Site') })] })) : (_jsxs("div", { className: 'flex items-center pt-1.5', children: [_jsx(UserIcon, { className: 'flex-1 text-purple-500' }), _jsx("span", { className: 'px-1.5 text-xs text-purple-500', children: translate('Participant') }), _jsx(Icon, { name: 'circle', color: 'gray', size: 5 }), _jsx("span", { className: 'px-1.5 text-xs', "data-testid": `participant-activity-available-time-${participantActivity?.c_task._id}`, children: timeRange })] }))] }), isSiteActivity && showStartButton && (_jsx("div", { className: 'ml-auto col-span-1', children: _jsx(Button, { onClick: onHandleActivityStart, label: translate('Start'), disabled: isActivityDisabled }) }))] }));
};
