import { getApiInstance } from '../../../helpers/api.helpers';
export const getFormInitialValues = ({ caregiver, totalCaregivers, }) => {
    return {
        email: caregiver?.c_email ?? undefined,
        displayName: caregiver?.c_caregiver_display_name ?? `caregiver-${totalCaregivers + 1}`,
    };
};
export const createAndInviteCaregiver = async ({ environment, form, participantId, }) => {
    const body = {
        displayName: form.displayName,
        email: form.email,
    };
    const path = `routes/c_public_users/${participantId}/caregiver/invite`;
    const createdCaregiver = await getApiInstance(environment).post(path, body, {
        params: {
            limit: 1,
            skip: 0,
            sort: JSON.stringify({ created: -1 }),
        },
    });
    return createdCaregiver?.data?.data;
};
export const createCaregiver = async ({ environment, form, participantId, }) => {
    return createAndInviteCaregiver({ environment, form, participantId });
};
export const updateCaregiver = async ({ environment, form, participantId, caregiverId, }) => {
    const body = {
        displayName: form.displayName,
        email: form.email,
        disableCaregiver: form.disableCaregiver,
    };
    const path = `routes/c_public_users/${participantId}/caregiver/${caregiverId}`;
    const updatedCaregiver = await getApiInstance(environment).put(path, body, {
        params: {
            limit: 1,
            skip: 0,
            sort: JSON.stringify({ created: -1 }),
        },
    });
    return updatedCaregiver?.data?.data;
};
export const sendCaregiverInviteEmail = async ({ org, site, environment, locale, caregiver, studyCode, }) => {
    const isUsingPinOnly = org?.c_subject_invite_validation === 'pin_only';
    const isPiiStudy = !org?.c_no_pii;
    if (isUsingPinOnly && isPiiStudy) {
        const defaultGroupId = org?.c_default_subject_group?._id;
        const inviteData = {
            c_site: site._id,
            c_public_user: caregiver.c_public_user._id,
            c_group: defaultGroupId,
            locale,
            c_email: caregiver.c_email,
            c_study_code: studyCode,
        };
        const inviteResponse = await getApiInstance(environment).get(`/routes/resend_invite`, {
            params: inviteData,
        });
        return inviteResponse?.data;
    }
};
